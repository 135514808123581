
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { DataTableHeader } from 'vuetify';
  import { Resource, ResourceType } from '@/interfaces';
  import { filter } from '@/helpers/filter';
  import ResourceModal from '@/components/modals/Resource.vue';
  import CountriesFilter from '@/components/filters/Countries.vue';

  const resourcesStore = namespace('resources');

  @Component({ components: { CountriesFilter, ResourceModal } })
  export default class extends Vue {
  showModal = false;
  itemToEdit: Resource | null = null;
  countryFilter: string[] = [];

  @resourcesStore.State
  readonly resources!: Resource[];

  mounted(): void {
    this.getResources(this.type);
  }

  @resourcesStore.Action
  getResources!: (type: ResourceType) => Resource[];

  @resourcesStore.Action
  removeResource!: (id: number) => Promise<void>;

  @resourcesStore.Action
  updateResource!: (data: Resource) => Promise<Resource | undefined>;

  @resourcesStore.Action
  addResource!: (data: Partial<Resource>) => Promise<void>;

  get type(): ResourceType {
    return this.$route.params.type as ResourceType;
  }

  get filtredResources(): Resource[] {
    return filter(this.resources, {
      country: this.countryFilter
    });
  }

  get headers(): DataTableHeader[] {
    return [
      { value: 'name', text: this.$t('general.name') as string },
      { value: 'country', text: this.$t('general.country') as string },
      { value: 'ops', text: '', align: 'end' },
    ]
  }

  @Watch('type')
  onTypeChanged(): void {
    this.getResources(this.type);
  }

  add(): void {
    this.itemToEdit = null;
    this.showModal = true;
  }

  edit(item: Resource): void {
    this.itemToEdit = item;
    this.showModal = true;
  }

  submit(data: Partial<Resource>): void {
    data.id ? this.updateResource(data as Resource) : this.addResource({ ...data, type: this.type })
  }

  remove(id: number): void {
    if (confirm(this.$t('general.areYouSure') as string)) {
      this.removeResource(id);
    }
  }
}
